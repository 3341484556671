import React from "react"
import PostCard from "./PostCard"
import styled from "styled-components"
import Anim from "./Anim"

const List = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
`

const PostList = ({ posts }) => {
  return (
    <>
      <section>
        <Anim>
          <h1>
          Deretan 10 Pasaran Togel Paling Terkenal di Indonesia
          </h1>
        </Anim>
        <Anim>
          <p>Hingga saat ini, ada ratusan pasaran resmi WLA yang beredar di seluruh dunia. Akan tetapi, tidak semua pasaran tersebut tersedia di berbagai situs judi online, terutama situs lokal. Maka dari itu, berikut kami sajikan beberapa pasaran togel yang begitu terkenal di tanah air kita:</p>
        </Anim>
        <Anim>
          <h2>Togel Sydney</h2>
          <p>Sydney merupakan salah satu kota di negara Australia, negara tetangga Indonesia. Tak heran, pasaran togel Sydney sangat digemari oleh seluruh pecinta togel dari Indonesia dan bahkan menjadi pasaran togel paling terkenal di Indonesia saat ini.</p>
          <p>Togel Sydney (SDY) sangat cocok dimainkan oleh para pemula maupun profesional. Hal ini karena konsep tebak angka yang ditawarkannya tidak terlalu sulit dan tidak terlalu mudah. Oleh karena itu, banyak pula para pemain togel baru yang berkesempatan mendapatkan jackpot ratusan juta rupiah.</p>
          <p>Hadiah yang di tawarkannya pun tidaklah main-main. Keuntungan yang didapatkan bisa mencapai 10 ribu x lipat modal yang kalian pertaruhkan. Apalagi, minimum modal yang diperlukan hanyalah senilai Rp 100 (seratus rupiah). Tentunya akan sangat menguntungkan dan menjadi salah satu cara instan bagi seseorang untuk kaya dengan mudah.</p>
          <p>Pasaran ini pun buka setiap hari full tanpa ada sehari libur pun. Jumlah putaran keluaran angkanya pun sekali sehari sama seperti togel SGP. Jadwalnya yaitu antara pukul 13.00 - 14.00 WIB. Karena jadwalnya yang berada di siang bolong, tentunya kalian tidak harus begadang untuk melihat hasil putaran angkanya.</p>
          <p>Kemudian, permainan yang ditawarkannya pun terbilang lengkap. Tersedia togel 2D, 4D, 3D, sesuai dengan pilihan nominal keuntungan yang akan kalian dapatkan. Jenis permainan yang paling digemari kebanyakan pengguna yaitu togel 4D karena menyediakan keuntungan yang lebih besar.</p>
          <a href="https://calcudokuonline.com">Live Draw Sydney Pools Tercepat di Indonesia</a>
        </Anim>
        <Anim>
          <h2>Togel Hongkong</h2>
          <p><a href="https://hongkongpools.co.uk">Togel Hongkong (HK)</a> yaitu togel unggulan yang memiliki banyak penggemar di Indonesia. Dengan menawarkan beragam keunggulan seperti hadiah yang melimpah, pilihan permainan yang beragam, dan kemudahan dalam bermain membuatnya jadi prioritas permainan bagi sebagian besar orang.</p>
          <p>Walaupun pasaran ini memiliki jadwal buka tutup setiap tengah malam, namun justru itu menjadi favorit para pecinta judi togel. Mereka rela begadang hingga tengah malam untuk menikmati permainan togel tersebut.</p>
          <p>Sejatinya, pasaran HK sudah berdiri sejak 1975 dan begitu terkenal di kalangan warga setempat. Di Indonesia sendiri saat itu masuk di awal tahun 1980 an, dan saat itu cukup populer di kalangan bettor.</p>
          <p>Namun, sejak tahun 1994 sejak adanya pemberlakuan pelarangan judi, akhirnya togel HK hanya bisa dinikmati secara online melalui beberapa situs judi online ternama baik situs lokal maupun luar negeri.</p>
          <p>Jadwal live drawnya sendiri yaitu sekitar pukul 23.00 setiap harinya mulai dari Senin - Minggu. Jumlah putaran per harinya hanya 1x sehari, walau begitu kalian bisa mendapatkan keuntungan besar hingga ribuan kali lipat modal yang kalian miliki.</p>
          <a href="https://chessmove.org">Live Draw HK Pools Tercepat di Indonesia</a>
        </Anim>
        <Anim>
          <h2>Togel Taiwan</h2>
          <p>Yang satu ini memang kurang dikenal oleh sebagian besar para togeller dari Indonesia. Akan tetapi akhir-akhir ini sudah cukup dikenali oleh sebagian besar kalangan pecinta togel online berkat layanannya yang lengkap dan hadiahnya yang besar.</p>
          <p>Biasanya para pecinta togel akan menggunakan beberapa sarana untuk memprediksi pasaran ini. Contohnya menggunakan paleo warna, firasat, hingga prediksi berdasarkan mimpi. Kalian dapat menggunakan beberapa sarana tersebut untuk menentukan sendiri prediksi keluaran angka dari togel Taiwan ini.</p>
          <p>Di situs resminya yaitu taiwanlotto.com, kalian bisa dengan mudah menemukan hasil angka keluaran yang diadakan secara rutin sekali sehari setiap harinya mulai dari Senin - Minggu pada pukul 20.45 WIB.</p>
          <p>Pasaran ini juga bisa menjadi salah satu alternatif terbaik dari pasaran China karena pasaran tersebut lebih sulit untuk kalian gunakan dibandingkan dengan pasaran togel paling terkenal di Indonesia satu ini yakni Togel Taiwan.</p>
          <p>Walau tergolong sebagai pasaran togel baru, namun kalian bisa dengan mudah menemukan beragam permainan togel seperti togel 2D, 3D, 4D, colok jitu, colok naga, colok macau, dan masih banyak lagi.</p>
          </Anim>
          <Anim>
          <h2>Togel China</h2>
          <p>China merupakan salah satu negara dengan perekonomian terbesar yang melegalkan togel. Oleh karena itu, kita mengenalnya dengan pasaran China yang memiliki begitu banyak varian prize untuk semua para penggunanya.</p>
          <p>Walaupun memiliki potensi kemenangan yang cenderung lebih sulit dibandingkan lainnya, namun togel China juga menjadi kegemaran tersendiri bagi sebagian kalangan. Hal ini dikarenakan Togel China menyediakan 3 prize utama dengan tambahan prize spesial yang begitu menguntungkan.</p>
          <p>Total kemenangan yang tersedia pun sangat besar yakni hingga ribuan kali lipat, bahkan ada beberapa situs judi online yang menyediakan hingga 10 ribu kali lipat. Tentunya, akan menjadi kemenangan yang besar apabila kalian sampai mendapatkan jackpot di pasaran China ini.</p>
          <p>Togel China memiliki web resmi yakni chinapools.asia. Ia menawarkan taruhan yang super lengkap dengan keluaran yang cepat. Namun, kalian tidak bisa mengunjunginya secara langsung tanpa VPN karena adanya pembatasan akses dari pemerintah Indonesia.</p>
          <p>Tidak ada hari libur di pasaran ini, setiap hari buka dengan jumlah putaran tebakan angka yakni sekali sehari pada pukul 15.15 WIB - 15.30 WIB. Kalian bisa memanfaatkan kesempatan tersebut untuk mendapatkan keuntungan sebanyak - banyaknya.</p>
          <p>Tak seperti pasaran lain, kalian pun bisa menebak hingga 6 urutan angka sekaligus. Jadi, potensi keuntungan yang didapatkan pun sangat banyak. Dengan modal ratusan rupiah, bisa berkesempatan memenangkan hadiah hingga puluhan dan ratusan juta. Menguntungkan bukan?</p>
        </Anim>  
        <Anim>
          <h2>Toto Macau</h2>  
          <p>Toto Macau bisa jadi pilihan yang tepat untuk kalian coba apabila menginginkan permainan yang simpel, jenis permainan yang beragam, dan kemenangan yang tinggi. Pasaran ini menawarkan diskon hingga 66% dan kemenangan hingga 9000 kali lipat untuk jenis pasaran togel 4D.</p>
          <p>Sebagai pasaran terpopuler di dunia, pasaran satu ini menawarkan hasil result selama 5 kali sehari. Tentunya sangat banyak jika dibandingkan pasaran lain yang hanya memiliki maksimal 2 kali keluaran angka per harinya.</p>
          <p>Jenis permainan yang tersedia pun sangatlah lengkap. Namun yang paling populer yaitu 2D, 3D, dan 4D yang masing-masing memiliki besaran hadiah yang berbeda satu sama lain. Kalian pun dapat menentukan sendiri tipe keluaran angka yang dipertaruhkan nantinya.</p>
          <p>Proses pengundian keluaran angka pada Toto Macau dilakukan secara jujur dengan menggunakan sistem bola billiard acak yang terdiri atas bola dengan angka 0 - 9. Nantinya, bola akan diputar sebanyak 4 kali untuk menentukan posisi ekor, kelapa, as, dan kop. Pengacakan sendiri dilakukan menggunakan bantuan mesin oleh dealer resmi Toto Macau.</p>
          <p>Awalnya, toto Macau hanya menyediakan 2 kali keluaran angka tiap harinya. Namun, akhirnya berkembang menjadi 4, dan saat ini sudah 5 kali putaran live draw tiap harinya. Yaitu pada pukul 13.00 WIB, 16.00 WIB, 19.00 WIB, 22.00 WIB, 24.00 WIB.</p> 
        </Anim>
        <Anim>
          <h2>Togel Singapore</h2>
          <p>Singapore atau Singapura merupakan negara terdekat dengan kita yang menyediakan pasaran togel resmi dari pemerintah setempat. Ia memiliki situs resmi di www.singaporepools.com.sg yang menyediakan informasi terbaru seputar putaran live draw tiap harinya.</p>
          <p>Nah, bila kalian ingin bertaruh pada pasaran Singapore (SGP) ini, maka harus memperhatikan jadwal buka tutupnya. Hal ini karena dalam seminggu hanya buka selama 5 hari saja, tidak seperti toto Macau yang buka terus setiap hari. Yaitu akan libur setiap hari Selasa dan Jumat dan akan aktif pada hari Senin, Rabu, Kamis, Sabtu, Minggu.</p>
          <p>Selain itu, jadwal live drawnya pun hanya sekali sehari yakni pada pukul 17:15 WIB - 17:38 WIB. Jadi, apabila kalian ingin menikmati taruhan secara intens, maka togel Singapore bukan pilihan yang tepat untuk kalian coba.</p>
          <p>Walau begitu, pasaran ini menyediakan beberapa tipe permainan yang lebih kompleks mulai dari toto online, Singapore Sweep, hingga togel 4D. Semua itu bisa kalian nikmati secara langsung dari satu pasaran ini.</p>
          <p>Dibandingkan pasaran lain, togel SGP pun terbilang mudah digunakan sebagai sarana taruhan. Tak  heran, ia menjadi salah satu dari 10 pasaran togel paling terkenal di Indonesia. sudah banyak juga pengguna dari Indonesia yang telah mendapatkan banyak keuntungan dari pasaran togel satu ini.</p>
        </Anim>
        <Anim>
          <h2>Togel PCSO</h2>
          <p>Pasaran togel Filipina biasa kita kenal dengan togel PCSO. Walau tergolong sebagai pasaran baru, namun ia sudah diakui dan terdaftar di lembaga Asosiasi Lottere Dunia (WLA). Jadi, kalian tidak perlu meragukan lagi keakuratannya.</p>
          <p>Selain itu, togel PCSO memiliki varian permainan yang super lengkap yang mungkin tidak akan kalian dapatkan di pasaran lainnya. Contohnya yaitu colok jitu, togel kombinasi, 2D, 3D, 4D, shio, colok naga, colok macau, dan masih banyak lagi.</p>
          <p>Minimum pembayaran taruhannya pun relatif kecil yakni Rp 100. Jadi, kalian tidak perlu memiliki modal besar untuk bisa bertaruh di togel satu ini. Apalagi, banyak situs judi lokal yang telah mendukung pasaran satu ini sehingga kalian dapat memainkannya secara mudah tanpa harus mendaftar di situs asing.</p>
          <p>Keluaran angka togel PCSO sama dengan togel China dan Taiwan, yakni sekali dalam sehari. Yaitu pada pukul 19.50 - 20.25 WIB. Sayangnya, tidak setiap hari buka. Ada satu hari yang libur dalam seminggunya yakni hari Minggu.</p>
          <p>Bagi yang  masih ragu untuk mendaftar di pasaran ini, maka kalian bisa mencobanya terlebih dahulu dengan modal yang relatif kecil. Jika memang cocok, maka bisa mencoba dengan modal yang jauh lebih besar sehingga potensi kemenangan yang kalian dapatkan pun akan semakin meningkat.</p>
        </Anim>  
        <Anim>
          <h2>Togel Seoul</h2>
          <p>Togel Seoul atau kadang disebut juga sebagai Seoul4D merupakan jenis pasaran yang berasal dari Korea Selatan. Memang, tidak semua situ togel online lokal menyediakan pasaran ini, akan tetapi popularitasnya cukup banyak yang ditandai dengan banyaknya permintaan para pelanggan terkait data paito Seoul.</p>
          <p>Seoul Lottery sendiri memiliki situs resminya di seoul-lottery.com dengan pengalaman lebih dari 12 tahun berdiri. Jackpot terbesar yang pernah tersedia di pasaran ini yakni 51,4 juta won. Tentunya, itu setara dengan world class jackpot dan world class prize.</p>
          <p>Pasaran ini buka setiap harinya mulai dari pukul 23.30 WIB - 23.50 atau bisa dibilang menjadi penutup dari berbagai pasaran yang ada. Untuk itu, kalian harus rela lembur malam agar bisa cepat-cepat melihat result keluaran togel terbarunya.</p>
          <p>Jenis permainan yang tersedia pun sangat banyak. Namun yang paling populer yakni togel 4D karena menawarkan hadiah paling besar dibandingkan lainnya serta menawarkan bonus tambahan yang super menguntungkan.</p>
        </Anim>
        <Anim>
          <h2>Bullseye</h2>
          <p>Bullseye termasuk salah satu togel terbaru yang menyediakan jenis togel 6D dengan hadiah resmi yang super menguntungkan. Oleh karena itu, banyak para pengguna baru yang rela mencoba pasaran ini karena tergiur akan keuntungannya yang luar biasa.</p>
          <p>Pasaran yang memiliki website resmi di bullseyelotto.com ini secara rutin melakukan live draw pada pukul 12.10 WIB - 13.10 WIB. Jadi, kalian tidak akan ketinggalan untuk melihat penayangan result setiap harinya mengingat waktunya yang berada di siang hari sehingga tidak perlu repot-repot  bangun tengah malam.</p>
          <p>Banyak juga situs lokal yang menyediakan result hasil pasaran Bullseye. Bahkan, sebagian dari mereka menyediakan langsung beberapa pilihan untuk sarana prediksi layaknya paito warna, dan sejenisnya. Kalian bisa memanfaatkannya untuk mendapatkan perkiraan keluaran angka togel secara tepat.</p>
          <p>Salah satu keunggulan yang ditawarkan oleh Bullseye yakni hadiah 6D berupa prize 1 yang menawarkan hadiah sangat besar. Sangat cocok digunakan oleh kalian yang berencana untuk mendapatkan jackpot besar-besaran dengan modal yang relatif kecil.</p>
        </Anim>
        <Anim>
          <h2>Togel Kentucky</h2>
          <p>Nah, di urutan terakhir ada togel Kentukcy, salah satu pilihan togel terbaik dan terpopuler selain dari Asia. Kalian bisa melihat secara langsung putarannya di website resmi miliknya yaitu www.kylottery.com.</p>
          <p>Togel Kentucky telah mendapatkan lisensi resmi dari World Lottery Association (WLA). Kalian pun tidak perlu khawatir dan ragu-ragu lagi akan keamanan yang ditawarkannya. Bahkan, sudah banyak para pemain lokal yang telah mendapatkan jackpot ratusan juta rupiah dari pasaran ini. Jadi, tidak perlu ragu untuk mencobanya.</p>
          <p>Pasaran ini pun terbagi menjadi 2 jenis yakni Kentucky Mid Day yang melakukan putaran live draw di siang hari tepatnya pada pukul 11.00 WIB. Sangat cocok untuk kalian yang tidak suka begadang di malam hari.</p>
          <p>Lalu, ada pasaran Kentucky Evening yang buka di malam hari yakni pukul 01.00 WIB. Bagi yang suka begadang maka silakan mencoba pasaran togel satu ini karena menawarkan beragam keunggulan yang tidak didapatkan di versi siang hari.</p>
          <p>Setiap hari buka dan tidak ada hari libur. Permainan yang ditawarkannya pun terbilang relatif banyak. Mulai dari togel colok naga, colok macau, togel 2D, 3D, 4D, dan masih banyak lainnya. Kalian bisa menemukannya sendiri di situs judi online Indonesia lainnya.</p>
          <p>Itu dia deretan 10 pasaran togel paling terkenal di Indonesia yang dapat kalian coba nantinya saat akan bertaruh di dunia pertogelan, semoga beruntung!</p>
        </Anim>
        <br></br>
        <Anim>
          <h2>
          Situs Judi Online Terbaik Di Indonesia
          </h2>
        </Anim>
        <Anim>
          <p>
          <a href="https://bk8.la">BK8</a> - Situs Taruhan online berperingkat #1 oleh <a href="https://www.inmoji.com">SBS™</a>
          </p>
          <p>
          W88 - cakupan olahraga terlengkap dan persyaratan rollover bonus terbaik
          </p>
          <p>
          M88 - alternatif yang bagus untuk W88
          </p>
          <p>
          CMD365 - campuran yang bagus dari promosi berulang
          </p>
        </Anim>
         <br></br>
        <Anim>
          <h2>
            Blogs
          </h2>
        </Anim>
        <List>
          {posts.map(({ node }, index) => {
            return <PostCard key={index} post={node} />
          })}
        </List>
      </section>
    </>
  )
}

export default PostList
